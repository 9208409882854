import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import { handleEnterKeyUp } from '../../../services/accessibility';
import { XSmall } from '../../icons/x-small';
import styles from './modal.scss';
import withSettingsColor from '../../../hoc/with-settings-color';
import withTranslate from '../../../../common/components/with-translate/with-translate';
import {
  getThemeTextColor,
  getThemeFill,
  getThemePrimaryBackground,
} from '../../../services/get-theme-style';

export const ModalLayout = ({
  className,
  borderColor,
  showCloseButton,
  onClose,
  children,
  t,
  theme,
}) => {
  const renderCloseButton = (onClose, style) => (
    <button
      aria-label={t('modal.close')}
      className={classNames(styles.closeIcon, 'modal__close', {
        'default-main-icon-fill': !style,
        [style]: style,
      })}
      onClick={onClose}
      onKeyUp={handleEnterKeyUp(onClose)}
    >
      <XSmall />
    </button>
  );

  const backgroundColorTheme = getThemePrimaryBackground(theme);
  const buttonFillTheme = getThemeFill(theme);
  const textColorTheme = getThemeTextColor(theme);

  return (
    <div
      className={classNames(styles.content, className, {
        'default-forum-card-background-color': !backgroundColorTheme,
        [backgroundColorTheme]: backgroundColorTheme,
        'color-5-text': !textColorTheme,
        [textColorTheme]: textColorTheme,
      })}
      data-hook="modal-layout"
      style={{ borderColor }}
    >
      {showCloseButton ? renderCloseButton(onClose, buttonFillTheme) : null}
      {children}
    </div>
  );
};

ModalLayout.propTypes = {
  className: PropTypes.string,
  borderColor: PropTypes.string,
  children: PropTypes.node,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  theme: PropTypes.string,
};

export default flowRight(
  withTranslate,
  withSettingsColor({
    propName: 'borderColor',
    siteColorFallback: 'color-5',
    siteColorAlpha: 0.2,
    fallbackColor: 'rgba(51, 51, 51, 0.2)',
  }),
)(ModalLayout);
