import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import ModalPage from './modal-page';
import ModalBackdrop from './modal-backdrop';
import ModalLayout from './modal-layout';
import { closeModalWithoutCallback } from './services/close-modal-without-callback';
import styles from './modal.scss';

export class ModalCentered extends Component {
  handleClose = () => closeModalWithoutCallback(this.props.onClose);

  render() {
    const { className, width, children, showBackdrop, showCloseButton, theme } = this.props;

    return (
      <ModalPage className={styles.centered} onClose={this.handleClose} showBackdrop={showBackdrop}>
        {showBackdrop && <ModalBackdrop onClose={this.handleClose} />}
        <ModalLayout
          className={classNames(styles[width], className)}
          children={children}
          showCloseButton={showCloseButton}
          onClose={this.handleClose}
          theme={theme}
        />
      </ModalPage>
    );
  }
}

ModalCentered.WIDTH_SMALL = 'widthSmall';
ModalCentered.WIDTH_MEDIUM = 'widthMedium';
ModalCentered.WIDTH_LARGE = 'widthLarge';
ModalCentered.WIDTH_VERY_LARGE = 'widthVeryLarge';

ModalCentered.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOf([
    ModalCentered.WIDTH_SMALL,
    ModalCentered.WIDTH_MEDIUM,
    ModalCentered.WIDTH_LARGE,
    ModalCentered.WIDTH_VERY_LARGE,
  ]).isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  showBackdrop: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  theme: PropTypes.string,
};

ModalCentered.defaultProps = {
  width: ModalCentered.WIDTH_SMALL,
  showBackdrop: true,
  showCloseButton: true,
};

export default ModalCentered;
