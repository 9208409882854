import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import CreatePostButton from '../create-post-button';
import EmptyStates from '../empty-states';
import withPermissions from '../../hoc/with-permissions';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import styles from './no-posts.scss';
import { getUniquePostTypesInAllCategories } from '../../../common/selectors/categories-selectors';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_FORUM_TABS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

class NoPosts extends React.Component {
  componentDidMount() {
    const { category, fetchMembersGroupsList } = this.props;
    if (isPrivate(category)) {
      fetchMembersGroupsList();
    }
  }

  getTextProps = () => {
    const {
      category: { postTypes = {} },
      noMemberPosts,
      t,
      isForumTabsEnabled,
    } = this.props;

    if (noMemberPosts) {
      return {
        title: t('my-posts-page.empty-state.no-posts.title'),
        content: t('my-posts-page.empty-state.no-posts.content'),
      };
    }

    if (postTypes.length > 1) {
      return {
        title: t('mixed-category-page.create-new-post.title'),
        content: t('mixed-category-page.create-new-post.body'),
      };
    }

    return {
      title: t('create-post-card.no-posts-title'),
      content: t(
        isForumTabsEnabled
          ? 'create-post-card.no-posts-subtitle-tabs'
          : 'create-post-card.no-posts-subtitle',
      ),
    };
  };

  render() {
    const {
      t,
      showCreatePostAction,
      category,
      type,
      uniquePostTypesInAllCategories,
      ...rest
    } = this.props;

    const postTypes =
      uniquePostTypesInAllCategories.length === 1
        ? uniquePostTypesInAllCategories
        : category.postTypes;

    return (
      <EmptyStates {...this.getTextProps()} type={type} {...rest}>
        <div className={styles.wrapper}>
          {showCreatePostAction && (
            <CreatePostButton
              className={styles.primaryButton}
              categorySlug={category.slug}
              postTypes={postTypes}
              isSmall={false}
              isDropdown={false}
              createPostCtaLabel={category.createPostCtaLabel}
            />
          )}
        </div>
      </EmptyStates>
    );
  }
}

NoPosts.propTypes = {
  category: PropTypes.object,
  showCreatePostAction: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func,
  type: PropTypes.string,
  noMemberPosts: PropTypes.bool,
  isForumTabsEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, { category, can }, actions) => {
  const showCreatePostAction =
    category &&
    ((category.isWriteProtected && can('create-post', 'category', category)) ||
      (!category.isWriteProtected && can('read', 'category', category)));

  return {
    fetchMembersGroupsList: actions.fetchMembersGroupsList,
    showCreatePostAction,
    openModal: actions.openModal,
    uniquePostTypesInAllCategories: getUniquePostTypesInAllCategories(state),
  };
};

export default flowRight(
  withPermissions,
  connect(mapRuntimeToProps),
  withTranslate,
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
  }),
)(NoPosts);
